// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-help-glossary-mdx": () => import("./../../../src/pages/help/glossary.mdx" /* webpackChunkName: "component---src-pages-help-glossary-mdx" */),
  "component---src-pages-help-how-to-mdx": () => import("./../../../src/pages/help/how-to.mdx" /* webpackChunkName: "component---src-pages-help-how-to-mdx" */),
  "component---src-pages-help-kactus-dot-json-mdx": () => import("./../../../src/pages/help/kactus-dot-json.mdx" /* webpackChunkName: "component---src-pages-help-kactus-dot-json-mdx" */),
  "component---src-pages-help-mdx": () => import("./../../../src/pages/help.mdx" /* webpackChunkName: "component---src-pages-help-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-org-tsx": () => import("./../../../src/pages/org.tsx" /* webpackChunkName: "component---src-pages-org-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-statement-tsx": () => import("./../../../src/pages/privacy-statement.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-tsx" */),
  "component---src-pages-release-notes-tsx": () => import("./../../../src/pages/release-notes.tsx" /* webpackChunkName: "component---src-pages-release-notes-tsx" */)
}

